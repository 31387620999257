<template>
    <v-card flat tile color="secondary">
        <v-card-text class="pt-1">
            <v-row class="fill-height">
                <v-col cols="12">
                    <v-card elevation="1" class="mb-3">
                        <v-card-text>
                            <SimilarProcessingActivityTitle
                                v-model="titleModel"
                                :formScope="formScope"
                                :disabled="!canEdit"
                            />
                        </v-card-text>
                    </v-card>
                    <v-card elevation="1">
                        <v-card-text>
                            <SimilarProcessingActivityDescription
                                v-model="descriptionModel"
                                :formScope="formScope"
                                :disabled="!canEdit"
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModelMixin from '@/components/vvt/ModelMixin';
import SimilarProcessingActivityTitle from './SimilarProcessingActivityTitle.vue';
import SimilarProcessingActivityDescription from './SimilarProcessingActivityDescription.vue';

export default {
    name: 'VvtRiskSimilarProcessingActivityPanel',
    mixins: [ModelMixin],
    components: {
        SimilarProcessingActivityTitle,
        SimilarProcessingActivityDescription,
    },
    props: {
        formScope: {
            type: String,
            default: null,
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            isActive: false,
        };
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            getChild: 'processingActivityModel/getChildProperty',
            workflowMode: 'processingActivityModel/getWorkflowModeState'
        }),
        canEdit () {
            return this.get('canEdit');
        },
        titleModel: {
            get() {
                return this.getChild({
                    property: 'similarProcessingActivity',
                    child: 'title',
                });
            },
            set(val) {
                this.setChild({
                    property: 'similarProcessingActivity',
                    child: 'title',
                    data: val,
                });
            },
        },
        descriptionModel: {
            get() {
                return this.getChild({
                    property: 'similarProcessingActivity',
                    child: 'description',
                });
            },
            set(val) {
                this.setChild({
                    property: 'similarProcessingActivity',
                    child: 'description',
                    data: val,
                });
            },
        },
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setProperty',
            setChild: 'processingActivityModel/setChildProperty',
        }),
        onToggle(val) {
            this.isActive = val;
        },
    }
};
</script>
