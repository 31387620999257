<template>
    <v-stepper-step
        class="disable-ripple"
        editable
        :complete="completed"
        :edit-icon="icon"
        :complete-icon="icon"
        :color="color"
        :step="question.id"
    ></v-stepper-step>
</template>

<script>
export default {
    name: 'VvtRiskQuestionMatrixPanelQuestionHeader',
    props: {
        question: Object,
    },
    computed: {
        completed() {
            return this.question.value !== null;
        },
        icon() {
            return this.question.value === null
                ? 'edit'
                : this.question.value
                ? 'priority_high'
                : 'done';
        },
        color() {
            return this.question.value === null
                ? 'primary'
                : this.question.value
                ? 'warning'
                : 'success';
        },
    },
};
</script>
