<template>
    <v-card flat tile color="secondary">
        <div>
            <v-tabs
                color="primary"
                class="elevation-3"
                background-color="transparent"
                icons-and-text
                v-model="riskTab"
                @change="selectTab($event)"
            >
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab v-if="!template" href="#similarProcessingActivity">
                    <span v-t="'vvtProcessEditor.risk.similarProcessingActivity.tabTitle'" />
                    <v-icon>mdi-shield-link-variant-outline</v-icon>
                </v-tab>

                <v-tab v-if="showBlacklistTab" href="#blacklist">
                    <span v-t="'vvtProcessEditor.risk.blacklist.tabTitle'" />
                    <v-icon>mdi-shield-half-full</v-icon>
                </v-tab>

                <v-tab v-if="!template" href="#questionMatrix">
                    <span v-t="'vvtProcessEditor.risk.questionMatrix.tabTitle'" />
                    <v-icon>mdi-shield-edit-outline</v-icon>
                </v-tab>

                <v-tab v-if="!template" href="#summary">
                    <span v-t="'vvtProcessEditor.risk.summary.tabTitle'" />
                    <v-icon>mdi-shield-check-outline</v-icon>
                </v-tab>

                <v-tab-item v-if="!template" :value="'similarProcessingActivity'">
                    <SimilarProcessingActivityPanel
                        :formScope="formScope"
                        @help-mode="$emit('help-mode', $event)"
                    />
                </v-tab-item>

                <v-tab-item v-if="showBlacklistTab" :value="'blacklist'">
                    <BlacklistPanel
                        :countryCode="countryCode"
                        :template="template"
                        :questionnaireQuestions="blacklistQuestionnaireQuestions"
                        @help-mode="$emit('help-mode', $event)"
                    />
                </v-tab-item>

                <v-tab-item v-if="!template" :value="'questionMatrix'">
                    <QuestionMatrixPanel
                        @input="questionMatrixStateChanged($event)"
                        @toggle-risk-tab="toggleRiskTab($event)"
                        @help-mode="$emit('help-mode', $event)"
                    />
                </v-tab-item>

                <v-tab-item v-if="!template" :value="'summary'">
                    <SummaryPanel
                        :formScope="formScope"
                        :create="create"
                        :blacklistQuestionnaireQuestions="blacklistQuestionnaireQuestions"
                        @help-mode="$emit('help-mode', $event)"
                    />
                </v-tab-item>
            </v-tabs>
        </div>
    </v-card>
</template>

<script>
import http from '@/utils/axios';
import { mapGetters } from 'vuex';
import SimilarProcessingActivityPanel from './SimilarProcessingActivity';
import BlacklistPanel from './Blacklist';
import QuestionMatrixPanel from './QuestionMatrix';
import SummaryPanel from './Summary';

export default {
    name: 'VvtRisk',
    components: {
        BlacklistPanel,
        QuestionMatrixPanel,
        SimilarProcessingActivityPanel,
        SummaryPanel
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        countryCode: {
            type: String,
            default: null,
        },
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            riskTab: 'riskAssessment',
            questionMatrixState: null,
            blacklistQuestionnaireQuestions: []
        };
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            getCompanyGroupSettings: 'companyGroupSettings/get'
        }),
        language () {
            return this.get('language');
        },
        showBlacklistTab() {
            return this.getCompanyGroupSettings('useBlacklistInfoTable') || this.getCompanyGroupSettings('blacklistQuestionnaire');
        }
    },
    methods: {
        questionMatrixStateChanged(val) {
            this.questionMatrixState = val;
        },
        toggleRiskTab(val) {
            this.riskTab = val;
        },
        selectTab(val) {
            let localPath = 'vvtProcessEditor.risk.' + val;
            this.$emit('help-mode', {
                title: this.$t(localPath + '.tabTitle'),
                text: this.$t(localPath + '.helpMode')
            });
        },        
        fetchBlacklistQuestionnaire () {
            if (this.getCompanyGroupSettings('blacklistQuestionnaire') && !this.get('template')) {
                this.$wait.start('fetch questionnaire');
                http(`/api/blacklist/questionnaire/questions`,
                {
                headers: { 'Accept-Language': `${this.language}` },
                })
                .then(response => {
                    if (!response.data.error) {
                        this.blacklistQuestionnaireQuestions = response.data;
                    } 
                    this.$wait.end('fetch questionnaire');
                })
                .catch(() => {
                    this.$wait.end('fetch questionnaire');
                })
            }
        },
    },
    mounted () {
        this.fetchBlacklistQuestionnaire();
    },
    watch: {
        language(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchBlacklistQuestionnaire();
            }
        }
    }
};
</script>
