<template>
    <v-text-field
        v-model="model"
        :label="$t('vvtProcessEditor.risk.similarProcessingActivity.similarActivityTitle.label')"
        :hint="$t('vvtProcessEditor.risk.similarProcessingActivity.similarActivityTitle.hint')"
        persistent-hint
        filled
        background-color="transparent"
        spellcheck="true"
        :disabled="disabled"
    />
</template>

<script>
import Debounceable from '@/components/Debounceable';

export default {
    name: 'VvtRiskSimilarProcessingActivityTitle',
    mixins: [Debounceable],
    props: {
        formScope: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
};
</script>
