<template>
    <v-card
        class="d-flex flex-column"
        elevation="0"
        height="100%"
        min-height="600"
    >
        <v-card-title class="text-subtitle-1">{{ question.id }}. {{ question.title }}</v-card-title>
        <v-card-text>
            <v-row>
                <v-col
                    cols="12"
                    :sm="5"
                >
                    <template v-if="question.audit">
                        <v-sheet elevation="0" class="mx-auto" width="100%">
                            <p
                                class="text-subtitle-2 font-weight-medium text-uppercase primary--text"
                                v-t="'vvtProcessEditor.risk.questionMatrix.audit'"
                            />
                            <div v-for="(x, i) in question.audit" :key="i">
                                <v-alert
                                    color="grey"
                                    icon="arrow_forward"
                                    class="v-alert--warning"
                                    outlined
                                >
                                    <span class="default-text-color" v-text="x" />
                                </v-alert>
                                <p
                                    v-if="question.audit.length > i + 1"
                                    class="warning--text font-weight-medium pt-3 pl-3"
                                >
                                    {{ $t('and').toUpperCase() }}
                                </p>
                            </div>
                        </v-sheet>
                    </template>
                </v-col>
                <v-col v-if="question.suggestion" cols="12" sm="4">
                    <template>
                        <v-sheet elevation="0" class="mx-auto" width="100%">
                            <p
                                class="text-subtitle-2 font-weight-medium text-uppercase primary--text"
                                v-t="'vvtProcessEditor.risk.questionMatrix.ourSuggestion'"
                            />
                            <div v-for="(x, i) in question.audit" :key="i">
                                <v-alert
                                    color="transparent"
                                    icon="priority_high"
                                    class="v-alert--warning"
                                    outlined
                                >
                                    <span class="default-text-color" v-text="x" />
                                </v-alert>
                            </div>
                        </v-sheet>
                    </template>
                </v-col>
                <v-col
                    cols="12"
                    :sm="6"
                    :offset-sm="1"
                >
                    <template v-if="question.examples">
                        <v-sheet elevation="0" class="mx-auto" width="100%">
                            <p
                                class="text-subtitle-2 font-weight-medium text-uppercase primary--text"
                                v-t="'vvtProcessEditor.risk.questionMatrix.examples'"
                            />
                            <div v-for="(x, i) in question.examples" :key="i">
                                <v-alert
                                    color="transparent"
                                    icon="priority_high"
                                    class="v-alert--warning"
                                    outlined
                                >
                                    <span class="default-text-color" v-text="x" />
                                </v-alert>
                            </div>
                        </v-sheet>
                    </template>
                </v-col>
            </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="mt-6 mb-2">
            <v-btn
                :disabled="disabled"
                color="warning"
                @click="answer(true)"
                large
                depressed
                min-width="200"
                v-t="'vvtProcessEditor.risk.questionMatrix.isTrue'"
            />
            <v-btn
                :disabled="disabled"
                color="success"
                @click="answer(false)"
                large
                depressed
                min-width="200"
                v-t="'vvtProcessEditor.risk.questionMatrix.isNotTrue'"
            />
            <v-spacer></v-spacer>
            <v-btn
                outlined
                color="grey"
                @click="nextQuestion()"
                large
                depressed
                min-width="200"
                v-t="'vvtProcessEditor.risk.questionMatrix.skipQuestion'"
            />
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'VvtRiskQuestionMatrixPanelQuestionItem',
    props: {
        question: Object,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    methods: {
        answer(typeOfAnswer) {
            this.$emit('answerQuestion', {
                id: this.question.id,
                answer: typeOfAnswer,
            });
            this.nextQuestion();
        },
        nextQuestion() {
            this.$emit('nextQuestion', this.question.id);
        },
    },
};
</script>
