<template>
    <v-card flat tile color="secondary">
        <v-card-text class="pt-1">
            <v-row class="fill-height">
                <v-col cols="12">
                    <v-card flat tile color="tertiary" :class="{'d-flex justify-start align-center': $vuetify.breakpoint.lgAndUp}" class="px-3 py-3 pr-0">
                        <div class="flex-grow-0 flex-shrink-0 text-center pr-3" style="min-width: 200px; max-width: 100%;"><div class="text-center"><v-icon v-text="'mdi-shield-lock-outline'" /><br /><span class="overline" v-t="'vvtProcessEditor.risk.riskAssessment'" /></div></div>
                        <LeaRadioGroup
                            :disabled="!canEditExtended"
                            v-model="riskyDataProcessingModel"
                            :items="gradeItems"
                            :formScope="formScope"
                            :step="7"
                            itemValue="flag"
                            itemText="title"
                            fieldName="riskAssessmentRiskyDataProcessing"
                            :label="$t('vvtProcessEditor.risk.riskyDataProcessing.label')"
                            :class="{'flex-grow-1 flex-shrink-0 mr-3': $vuetify.breakpoint.lgAndUp, 'mb-3': $vuetify.breakpoint.mdAndDown}"
                            @help-mode="$emit('help-mode', {
                                title: $t('vvtProcessEditor.risk.riskyDataProcessing.label'),
                                text: $t('vvtProcessEditor.risk.riskyDataProcessing.helpMode')
                            })"
                        />
                        <LeaRadioGroup
                            :disabled="!canEditExtended"
                            v-model="riskWithMeasuresModel"
                            :items="gradeItems"
                            :formScope="formScope"
                            :step="7"
                            itemValue="flag"
                            itemText="title"
                            fieldName="riskAssessmentRiskWithMeasures"
                            :label="$t('vvtProcessEditor.risk.summary.riskWithMeasures.label')"
                            :class="{'flex-grow-1 flex-shrink-0 mr-3': $vuetify.breakpoint.lgAndUp}"
                            :create="create"
                            @help-mode="$emit('help-mode', {
                                title: $t('vvtProcessEditor.risk.summary.riskWithMeasures.label'),
                                text: $t('vvtProcessEditor.risk.summary.riskWithMeasures.helpMode')
                            })"
                        />
                    </v-card>
                </v-col>
                <v-col v-if="riskQuestionMatrix" cols="12">
                    <v-card flat tile color="tertiary" :class="{'d-flex justify-start align-center': $vuetify.breakpoint.lgAndUp}" class="px-3 py-3 pr-0">
                        <div class="flex-grow-0 flex-shrink-0 pr-3" style="min-width: 200px; max-width: 100%;"><div class="text-center"><v-icon v-text="'mdi-shield-edit-outline'" /><br /><span class="overline" v-t="'vvtProcessEditor.risk.questionMatrix.tabTitle'" /></div></div>
                        <div class="flex-grow-1 flex-shrink-0">
                            <v-alert
                                border="bottom"
                                colored-border
                                :type="riskQuestionMatrixCount === 0 ? 'success' : riskQuestionMatrixCount === 1 ? 'info' : riskQuestionMatrixCount === 2 ? 'warning' : 'error'"
                                :icon="riskQuestionMatrixCount === 1 ? 'mdi-information-outline' : undefined"
                                elevation="2"
                                :color="riskQuestionMatrixCount === 1 ? 'yellow darken-3' : riskQuestionMatrixCount === 2 ? 'orange darken-3' : undefined"
                                class="tertiary black--text mr-3"
                                :class="{'mt-1 mb-1': riskQuestionMatrixCount === 0}"
                            >   
                                <span v-text="$tc('vvtProcessEditor.risk.questionMatrix.resultInfoCount', riskQuestionMatrixCount)"></span>
                            </v-alert>
                            <v-list v-if="riskQuestionMatrixCount > 0" dense class="mr-3">
                                <v-list-item
                                v-for="(item, i) in riskQuestionMatrixNames"
                                :key="i"
                                >
                                <v-list-item-content>
                                    <v-list-item-title v-text="item"></v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card>
                </v-col>
                <v-col v-if="blacklistQuestions" cols="12">
                    <v-card flat tile color="tertiary" :class="{'d-flex justify-start align-center': $vuetify.breakpoint.lgAndUp}" class="px-3 py-3 pr-0">
                        <div class="flex-grow-0 flex-shrink-0 pr-3" style="min-width: 200px; max-width: 100%;"><div class="text-center"><v-icon v-text="'mdi-shield-half-full'" /><br /><span class="overline" v-t="'vvtProcessEditor.risk.blacklist.tabTitle'" /></div></div>
                        <div class="flex-grow-1 flex-shrink-0">
                             <v-alert
                                border="bottom"
                                colored-border
                                :type="blacklistQuestionsCount === 0 ? 'success' : blacklistQuestionsCount === 1 ? 'info' : blacklistQuestionsCount === 2 ? 'warning' : 'error'"
                                :icon="blacklistQuestionsCount === 1 ? 'mdi-information-outline' : undefined"
                                elevation="2"
                                :color="blacklistQuestionsCount === 1 ? 'yellow darken-3' : blacklistQuestionsCount === 2 ? 'orange darken-3' : undefined"
                                class="tertiary black--text mr-3"
                                :class="{'mt-1 mb-1': blacklistQuestionsCount === 0}"
                            >   
                                <span v-text="$tc('vvtProcessEditor.risk.questionMatrix.resultInfoCount', blacklistQuestionsCount)"></span>
                            </v-alert>
                            <v-list v-if="blacklistQuestionsCount > 0" dense class="mr-3">
                                <v-list-item
                                v-for="(item, i) in blacklistQuestionsNames"
                                :key="i"
                                >
                                <v-list-item-content>
                                    <v-list-item-title v-text="item"></v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card flat tile color="tertiary" :class="{'d-flex justify-start align-center': $vuetify.breakpoint.lgAndUp}" class="px-3 py-3 pr-0">
                        <div class="flex-grow-0 flex-shrink-0 pr-3" style="min-width: 200px; max-width: 100%;"><div class="text-center"><v-icon v-text="'mdi-shield-link-variant-outline'" /><br /><span class="overline" v-t="'vvtProcessEditor.risk.similarProcessingActivity.tabTitle'" /></div></div>
                        <div class="flex-grow-1 flex-shrink-0">
                            <v-alert
                                v-if="!similarRecord"
                                border="bottom"
                                colored-border
                                type="info"
                                color="yellow darken-3"
                                icon="mdi-information-outline"
                                elevation="2"
                                class="tertiary black--text mr-3 mt-1 mb-1"
                            >   
                                <span v-t="'vvtProcessEditor.risk.similarProcessingActivity.notDefined'"></span>
                            </v-alert>
                            <v-card v-else flat class="mr-3">
                                <v-card-title v-if="similarRecordTitle" v-text="similarRecordTitle" />
                                <v-card-text v-if="similarRecordDescription" v-text="similarRecordDescription" />
                            </v-card>
                        </div>
                    </v-card>
                </v-col>
                <v-col v-if="blacklistQuestions" cols="12">
                    <v-card flat tile color="tertiary" :class="{'d-flex justify-start align-center': $vuetify.breakpoint.lgAndUp}" class="px-3 py-3 pr-0">
                        <div class="flex-grow-0 flex-shrink-0 pr-3" style="min-width: 200px; max-width: 100%;"><div class="text-center"><v-icon v-text="'mdi-shield-sync-outline'" /><br /><span class="overline" v-t="'vvtProcessEditor.risk.thirdCountry.tabTitle'" /></div></div>
                        <div class="flex-grow-1 flex-shrink-0">
                             <v-alert
                                border="bottom"
                                colored-border
                                :type="!safeThirdCountry.length && !unsafeThirdCountry.length ? 'success' : unsafeThirdCountry.length ? 'warning' : 'success'"
                                elevation="2"
                                :color="unsafeThirdCountry.length ? 'orange darken-3' : safeThirdCountry === 2 ? 'yellow darken-3' : undefined"
                                class="tertiary black--text mr-3"
                                :class="{'mt-1 mb-1': !safeThirdCountry.length && !unsafeThirdCountry.length}"
                            >   
                                <span v-if="unsafeThirdCountry.length" v-text="$tc('vvtProcessEditor.risk.thirdCountry.resultInfoCountUnsafe', unsafeThirdCountry.length)"></span>
                                <span v-else-if="safeThirdCountry.length" v-text="$tc('vvtProcessEditor.risk.thirdCountry.resultInfoCountSafe', safeThirdCountry.length)"></span>
                                <span v-else v-text="$t('vvtProcessEditor.risk.thirdCountry.resultInfoCountNone')"></span>
                            </v-alert>
                            <v-list v-if="unsafeThirdCountry.length" dense class="mr-3">
                                <v-list-item
                                v-for="(item, i) in unsafeThirdCountry"
                                :key="i"
                                >
                                <v-list-item-icon v-if="item.countryCode">
                                    <v-avatar size="24" class="ml-0 float-left">
                                        <img
                                            :src="`${$publicPath}images/countries/${item.countryCode.toLowerCase()}.svg`"
                                            :alt="item.countryName">
                                    </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.countryName"></v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6">
                    <LeaDropdown
                        type="select"
                        :disabled="!canEditExtended"
                        :formScope="formScope"
                        :step="7"
                        fieldName="riskDpiaRequirement"
                        :label="$t('vvtProcessEditor.risk.summary.dpiaRequirement.label')"
                        :hint="$t('vvtProcessEditor.risk.summary.dpiaRequirement.hint')"
                        :create="create"
                        v-model="dpiaRequirementModel"
                        :items="items"
                        item-value="dpiaRequirement"
                        item-text="title"
                        clearable
                        @help-mode="$emit('help-mode', {
                            title: $t('vvtProcessEditor.risk.summary.dpiaRequirement.label'),
                            text: $t('vvtProcessEditor.risk.summary.dpiaRequirement.helpMode')
                        })"
                    >
                        <template v-slot:selection="{ item }">
                            <v-row no-gutters align="center">
                                <v-icon v-if="item.dpiaRequirement === 'LOW'" color="success" class="mr-3">mdi-check-circle</v-icon>
                                <v-icon v-if="item.dpiaRequirement === 'MEDIUM'" color="orange darken-3" class="mr-3">mdi-check-circle</v-icon>
                                <v-icon v-if="item.dpiaRequirement === 'HIGH'" color="error" class="mr-3">mdi-alert</v-icon>
                                <span :class="item.dpiaRequirement === 'HIGH' ? 'red--text' : null">{{ item.title }}</span>
                            </v-row>
                        </template>
                        <template v-slot:item="{active, item, attrs, on}" :active="active">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-row no-gutters align="center">
                                            <v-icon v-if="item.dpiaRequirement === 'LOW'" color="success" class="mr-3">mdi-check-circle</v-icon>
                                            <v-icon v-if="item.dpiaRequirement === 'MEDIUM'" color="orange darken-3" class="mr-3">mdi-check-circle</v-icon>
                                            <v-icon v-if="item.dpiaRequirement === 'HIGH'" color="error" class="mr-3">mdi-alert</v-icon>
                                            <span :class="active && item.dpiaRequirement === 'HIGH' ? 'red--text' : null">{{ item.title }}</span>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </LeaDropdown>
                </v-col>
                <v-col cols="12" lg="6">
                    <LeaSwitch
                        :disabled="!canEditExtended"
                        :formScope="formScope"
                        :step="7"
                        fieldName="riskDpiaCompleted"
                        :label-on="$t('vvtProcessEditor.risk.summary.dpiaCompleted.label')"
                        :label-off="$t('vvtProcessEditor.risk.summary.dpiaNotCompleted.label')"
                        :hint="$t('vvtProcessEditor.risk.summary.dpiaCompleted.hint')"
                        :create="create"
                        v-model="dpiaCompletedModel"
                        color="success"
                        @help-mode="$emit('help-mode', {
                            title: $t('vvtProcessEditor.risk.summary.dpiaCompleted.label'),
                            text: $t('vvtProcessEditor.risk.summary.dpiaCompleted.helpMode')
                        })"
                    />
                </v-col>
                <v-col cols="12" lg="6">
                    <LeaTextArea
                        :disabled="!canEditExtended"
                        :formScope="formScope"
                        :step="7"
                        fieldName="riskAssessmentNote"
                        :label="$t('vvtProcessEditor.risk.summary.riskAssessmentNote.label')"
                        :hint="$t('vvtProcessEditor.risk.summary.riskAssessmentNote.hint')"
                        :create="create"
                        v-model="riskAssessmentNote"
                        @help-mode="$emit('help-mode', {
                            title: $t('vvtProcessEditor.risk.summary.riskAssessmentNote.label'),
                            text: $t('vvtProcessEditor.risk.summary.riskAssessmentNote.helpMode')
                        })"
                    />
                </v-col>
                <v-col cols="12" lg="6">
                    <LeaTextArea
                        :disabled="!canEditExtended"
                        :formScope="formScope"
                        :step="7"
                        fieldName="riskDpiaNote"
                        :label="$t('vvtProcessEditor.risk.summary.dpiaNote.label')"
                        :hint="$t('vvtProcessEditor.risk.summary.dpiaNote.hint')"
                        :create="create"
                        v-model="dpiaNoteModel"
                        @help-mode="$emit('help-mode', {
                            title: $t('vvtProcessEditor.risk.summary.dpiaNote.label'),
                            text: $t('vvtProcessEditor.risk.summary.dpiaNote.helpMode')
                        })"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModelMixin from '@/components/vvt/ModelMixin';
import LeaTextArea from '@/components/Input/LeaTextArea';
import LeaDropdown from '@/components/Input/LeaDropdown';
import LeaSwitch from '@/components/Input/LeaSwitch';
import LeaRadioGroup from '@/components/Input/LeaRadioGroup';

export default {
    name: 'VvtRiskSummaryPanel',
    mixins: [ModelMixin],
    components: {
      LeaTextArea,
      LeaDropdown,
      LeaSwitch,
      LeaRadioGroup
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        create: {
            type: Boolean,
            default: false,
        },
        blacklistQuestionnaireQuestions: {
            type: Array,
            default: () => [],
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            isActive: false,
            gradeItems: [
                {
                    flag: 'low',
                    color: 'success',
                    title: 'gradeItems.low',
                },
                {
                    flag: 'medium',
                    color: 'orange',
                    title: 'gradeItems.medium',
                },
                {
                    flag: 'high',
                    color: 'red lighten-2',
                    title: 'gradeItems.high',
                },
                {
                    flag: 'veryHigh',
                    color: 'red darken-2',
                    title: 'gradeItems.veryHigh',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            pa: 'processingActivityModel/getModel',
            get: 'processingActivityModel/getProperty',
            getChild: 'processingActivityModel/getChildProperty',
            getCompanyGroupSettings: 'companyGroupSettings/get',
            getCompanyById: 'companies/getById',
            findByCountryCode: 'countries/findByCountryCode',
        }),
        recipientCountries () {
            let countries = [];
            this.get('externalRecipients').forEach(x => {
                if(x.country) {
                    let country = this.findByCountryCode(x.country);
                    if(country) {
                        countries.push(country);
                    }

                }
            });
            this.get('corporationRecipients').forEach(x => {
                if(x.corporationCompany) {
                    let company = this.getCompanyById(x.corporationCompany);
                    if(company.countryCode) {
                        let country = this.findByCountryCode(company.countryCode);  
                        if(country) {
                            countries.push(country);
                        }
                    }
                }
            });
            return countries;
        },
        safeThirdCountry () {
            return this.recipientCountries.filter(x => {
                if(!x.eea && !x.eu && x.safeThirdCountry) {
                    return x;
                }
            });
        },
        unsafeThirdCountry () {
            return this.recipientCountries.filter(x => {
                if(!x.eea && !x.eu && !x.safeThirdCountry) {
                    return x;
                }
            });
        },
        canEdit () {
            return this.get('canEdit');
        },
        canEditExtended () {
            return this.get('canEditExtended');
        },
        riskQuestionMatrix () {
            return this.get('riskQuestionMatrix') ? this.get('riskQuestionMatrix') : null;
        },
        riskQuestionMatrixCount () {
            let count = 0;
            if (this.riskQuestionMatrix) {
                for (var i = 1, len = 10; i < len; i++) {
                    if(this.riskQuestionMatrix['question' + i]) {
                        ++count;
                    }
                }
            }
            return count;
        },
        riskQuestionMatrixNames () {
            let names = [];
            if (this.riskQuestionMatrix) {
                for (var i = 0, len = 9; i < len; i++) {
                    if(this.riskQuestionMatrix['question' + (i + 1)]) {
                    names.push((i + 1) + '. ' + this.$t('vvtProcessEditor.risk.questionMatrix.questions.' + i + '.title' ));
                    }
                }
            }
            return names;
        },
        blacklistQuestions () {
            return this.getCompanyGroupSettings('blacklistQuestionnaire') && this.get('blacklistQuestionResult') ? this.get('blacklistQuestionResult') : null;
        },
        blacklistQuestionsCount () {
            let count = 0;
            if (this.blacklistQuestions) {
                this.blacklistQuestions.forEach(x => {
                    if(x['answer'] === 1) {
                        ++count;
                    }
                })
            }
            return count;
        },
        blacklistQuestionsNames () {
            let questionIds = [];
            let names = [];
            if (this.blacklistQuestions) {
                this.blacklistQuestions.forEach(x => {
                    if(x['answer'] === 1) {
                        questionIds.unshift(x.blacklistQuestionId);
                    }
                })
            }
            questionIds.forEach(questionId => {
                const questionIndex = this.blacklistQuestionnaireQuestions.findIndex(x => x.id === questionId)
                if(questionIndex > -1) {
                    names[questionIndex] = (questionIndex + 1) + '. ' + this.blacklistQuestionnaireQuestions[questionIndex].title;
                }
            })
            return names.filter(x => x !== null);
        },
        similarRecord () {
            return !!(this.similarRecordTitle || this.similarRecordDescription)
        },
        similarRecordTitle () {
            return this.getChild({
                property: 'similarProcessingActivity',
                child: 'title',
            });
        },
        similarRecordDescription () {
            return this.getChild({
                property: 'similarProcessingActivity',
                child: 'description',
            });
        },
        riskyDataProcessingModel: {
            get() {
                return this.getChild({
                    property: 'riskAssessment',
                    child: 'riskyDataProcessing',
                });
            },
            set(val) {
                this.setChild({
                    property: 'riskAssessment',
                    child: 'riskyDataProcessing',
                    data: val,
                });
            }
        },
        dpiaRequirementModel: {
            get() {
                return this.get('dpiaRequirement');
            },
            set(val) {
                this.set({
                    property: 'dpiaRequirement',
                    data: val,
                });
            }
        },
        dpiaCompletedModel: {
            get() {
                return this.get('dpiaCompleted');
            },
            set(val) {
                this.set({
                    property: 'dpiaCompleted',
                    data: val,
                });
            }
        },
        riskAssessmentNote: {
            get() {
                return this.get('riskAssessmentNote');
            },
            set(val) {
                this.set({
                    property: 'riskAssessmentNote',
                    data: val,
                });
            }
        },
        dpiaNoteModel: {
            get() {
                return this.get('dpiaNote');
            },
            set(val) {
                this.set({
                    property: 'dpiaNote',
                    data: val,
                });
            }
        },
        riskWithMeasuresModel: {
            get() {
                return this.getChild({
                    property: 'riskAssessment',
                    child: 'riskWithMeasures',
                });
            },
            set(val) {
                this.setChild({
                    property: 'riskAssessment',
                    child: 'riskWithMeasures',
                    data: val,
                });
            }
        },
        items () {
          return [
              {
                dpiaRequirement: 'LOW',
                title: this.$t('vvtProcessEditor.risk.summary.dpiaRequirement.dpiaRequirements.LOW')
              },
              {
                dpiaRequirement: 'MEDIUM',
                title: this.$t('vvtProcessEditor.risk.summary.dpiaRequirement.dpiaRequirements.MEDIUM')
              },
              {
                dpiaRequirement: 'HIGH',
                title: this.$t('vvtProcessEditor.risk.summary.dpiaRequirement.dpiaRequirements.HIGH')
              }
          ]
        },
        language () {
            return this.get('language');
        }
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setProperty',
            setChild: 'processingActivityModel/setChildProperty',
        })
    }
};
</script>
