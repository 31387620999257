var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":"","color":"secondary"}},[_c('v-card-text',[_c('v-card',{staticClass:"mb-2",attrs:{"elevation":"1"}},[_c('v-card-text',[_c('p',{directives:[{name:"t",rawName:"v-t",value:('vvtProcessEditor.risk.questionMatrix.stateTitle.' + _vm.stateKey),expression:"'vvtProcessEditor.risk.questionMatrix.stateTitle.' + stateKey"}],class:_vm.stateColor}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('vvtProcessEditor.risk.questionMatrix.stateText.' + _vm.stateKey),expression:"'vvtProcessEditor.risk.questionMatrix.stateText.' + stateKey"}]})]),_c('v-card-actions',[( _vm.questionMatrixState !== 'too_few_questions_answered')?_c('v-btn',{attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){return _vm.$emit(
                            'toggle-risk-tab',
                            _vm.questionMatrixState === 'dsfa_needed'
                                ? 'similarProcessingActivity'
                                : 'blacklist'
                        )}}},[_c('v-icon',[_vm._v("keyboard_arrow_right")]),_vm._v(" "+_vm._s(_vm.$t('continue'))+" ")],1):_vm._e(),_c('v-spacer'),(
                        _vm.questionMatrixState === 'dsfa_not_needed' ||
                            _vm.questionMatrixState === 'dsfa_needed'
                    )?_c('v-btn',{staticClass:"text-none",attrs:{"disabled":!_vm.canEdit,"depressed":"","small":""},on:{"click":function($event){return _vm.resetQuestionMatrix()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("settings_backup_restore")]),_vm._v(" "+_vm._s(_vm.$t('vvtProcessEditor.risk.questionMatrix.resetQuestionMatrix'))+" ")],1):_vm._e()],1)],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-stepper',{staticClass:"px-3 elevation-1",attrs:{"non-linear":""},model:{value:(_vm.questionModel),callback:function ($$v) {_vm.questionModel=$$v},expression:"questionModel"}},[_c('v-stepper-header',[_vm._l((_vm.questions),function(question){return [_c('QuestionHeader',{key:question.id,attrs:{"question":question}}),(_vm.questions.length > question.id)?_c('v-divider',{key:question.text}):_vm._e()]})],2),_c('v-stepper-items',_vm._l((_vm.questions),function(question,i){return _c('v-stepper-content',{key:i,attrs:{"step":question.id}},[_c('QuestionItem',{attrs:{"disabled":!_vm.canEdit,"question":question},on:{"answerQuestion":function($event){return _vm.answerQuestion($event)},"nextQuestion":function($event){return _vm.nextQuestion($event)}}})],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }