<template>
  <v-card
    @click.passive="emitHelpMode"
    @focus.capture="emitHelpMode"
    align-start
    class="default"
    height="100%"
    elevation="1"
  >
      <v-card-title primary-title class="pb-0 pt-3">
          <div class="v-label">
              {{ label }}
          </div>
      </v-card-title>
      <v-card-text class="px-3 pb-3">
        <v-radio-group
            row
            v-model="model"
            :hint="hint"
            persistent-hint
            :error-messages="
                errors.collect(
                    formScope + '.' + fieldName + rowId
                )
            "
            :data-vv-name="fieldName + rowId"
            :data-vv-scope="formScope"
            v-validate="required ? 'required' : ''"
            :required="required"
            :disabled="loading || disabled || disabledComp"
            :loading="loading"
            @change="$emit('modified', $event)"
        >
            <v-radio
                row
                v-for="radio in items"
                :label="$te(radio[itemText]) ? $t(radio[itemText]) : radio[itemText]"
                :value="radio[itemValue]"
                :key="radio[itemValue]"
                :color="radio.color ? radio.color : 'primary'"
            ></v-radio>
        </v-radio-group>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
    inject: {
        $validator: '$validator',
    },
    data () {
        return {
            disabledComp: false
        }
    },
    methods: {
        disable () {
            this.disabledComp = true;
        },
        enable () {
            this.disabledComp = false;
        },
        emitHelpMode () {
            if (this.helpModeText) {
                this.$emit('help-mode', {
                    title: this.label,
                    text: this.helpModeText,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            } else {
                this.$emit('help-mode', {
                    step: this.step,
                    name: this.fieldName,
                    group: this.groupName,
                    fieldName: this.fieldName,
                    childFieldName: this.childFieldName
                })
            }
        }
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        groupName: {
            type: String,
            default: null,
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        create: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        value: {
            type: [Number, String],
            default: null,
        },
        items: {
          type: Array,
          default: () => []
        },
        itemValue: {
            type: String,
            default: 'id',
        },
        itemText: {
            type: String,
            default: 'title',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        rowId: {
            type: [Number, String],
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit('input', val);
            }
        }
    },
    watch: {
        disabled (val) {
            this.disabledComp = val;
        }
    }
};
</script>
