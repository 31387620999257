<template>
    <v-card flat tile color="secondary">
        <v-card-text>
            <v-card elevation="1" class="mb-2">
                <v-card-text>
                    <p :class="stateColor" v-t="'vvtProcessEditor.risk.questionMatrix.stateTitle.' + stateKey" />
                    <p v-t="'vvtProcessEditor.risk.questionMatrix.stateText.' + stateKey" />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-if=" questionMatrixState !== 'too_few_questions_answered'"
                        @click="
                            $emit(
                                'toggle-risk-tab',
                                questionMatrixState === 'dsfa_needed'
                                    ? 'similarProcessingActivity'
                                    : 'blacklist'
                            )
                        "
                        color="primary"
                        depressed
                        small
                    >
                        <v-icon>keyboard_arrow_right</v-icon>
                        {{ $t('continue') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        v-if="
                            questionMatrixState === 'dsfa_not_needed' ||
                                questionMatrixState === 'dsfa_needed'
                        "
                        :disabled="!canEdit"
                        class="text-none"
                        depressed
                        small
                        @click="resetQuestionMatrix()"
                    >
                        <v-icon left>settings_backup_restore</v-icon>
                        {{ $t('vvtProcessEditor.risk.questionMatrix.resetQuestionMatrix') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <div
                style="position:relative"
            >
                <v-stepper
                    class="px-3 elevation-1"
                    v-model="questionModel"
                    non-linear
                >
                    <v-stepper-header>
                        <template v-for="question in questions">
                            <QuestionHeader
                                :question="question"
                                :key="question.id"
                            />
                            <v-divider
                                v-if="questions.length > question.id"
                                :key="question.text"
                            ></v-divider>
                        </template>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content
                            v-for="(question, i) in questions"
                            :key="i"
                            :step="question.id"
                        >
                            <QuestionItem
                                :disabled="!canEdit"
                                :question="question"
                                @answerQuestion="answerQuestion($event)"
                                @nextQuestion="nextQuestion($event)"
                            />
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import QuestionHeader from './QuestionHeader.vue';
import QuestionItem from './QuestionItem.vue';

export default {
    name: 'VvtRiskQuestionMatrixPanel',
    components: {
        QuestionHeader,
        QuestionItem,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            getChild: 'processingActivityModel/getChildProperty',
            workflowMode: 'processingActivityModel/getWorkflowModeState'
        }),
        canEdit () {
            return this.get('canEdit');
        },
        questionModel: {
            get() {
                return this.getChild({
                    property: 'riskQuestionMatrix',
                    child: 'currentQuestion',
                });
            },
            set(val) {
                this.setChild({
                    property: 'riskQuestionMatrix',
                    child: 'currentQuestion',
                    data: val,
                });
            },
        },
        questions() {
            const arrReturn = [];
            let i;
            const questionMatrix = this.get('riskQuestionMatrix');
            if (!questionMatrix) {
                return arrReturn;
            }
            for (i = 0; i < 9; i++) {
                const id = i + 1;
                const lang = this.$i18n.t(
                    'vvtProcessEditor.risk.questionMatrix.questions.' + i
                );
                arrReturn[i] = {
                    ...lang,
                    ...{
                        id: id,
                        value: questionMatrix['question' + id],
                    },
                };
            }
            return arrReturn;
        },
        questionMatrixState() {
            const lengthAll = this.questions.length;
            const lengthFalse = this.questions.filter(x => x.value === false)
                .length;
            const lengthTrue = this.questions.filter(x => x.value === true)
                .length;

            let questionMatrixState = '';

            if (lengthFalse === lengthAll) {
                questionMatrixState = 'dsfa_not_needed';
            } else if (lengthTrue) {
                questionMatrixState = 'dsfa_needed';
            } else {
                questionMatrixState = 'too_few_questions_answered';
            }
            this.$emit('input', questionMatrixState);
            return questionMatrixState;
        },
        stateKey() {
            return this.questionMatrixState.toLowerCase();
        },
        stateColor() {
            return this.questionMatrixState === 'too_few_questions_answered'
                ? 'warning--text'
                : this.questionMatrixState === 'dsfa_needed'
                ? 'warning--text'
                : 'success--text';
        },
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setProperty',
            setChild: 'processingActivityModel/setChildProperty',
            resetQuestionMatrix: 'processingActivityModel/resetQuestionMatrix',
        }),
        answerQuestion(event) {
            const data = {};
            data.currentQuestion = this.questionModel;
            data.result = this.questionMatrixState;
            data.id = event.id;
            data.answer = event.answer;
            this.$store.dispatch(
                'processingActivityModel/setRiskQuestionMatrix',
                data
            );
        },
        nextQuestion(step) {
            this.questionModel = this.questionModel >= this.questions.length ? 1 : step + 1;
        }
    },
    watch: {
        questionMatrixState(newVal) {
            this.$store.dispatch(
                'processingActivityModel/setRiskQuestionMatrix',
                { currentQuestion: this.questionModel, result: newVal }
            );
        },
    }
};
</script>
